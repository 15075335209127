import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ServicePortalRssFeedItem } from './ServicePortalRssFeedItem';
import { ServicePortalRssFeedProps, Item, Result } from './types';

export const ServicePortalRssFeed = (props: ServicePortalRssFeedProps) => {
  const { descriptiveText, displayOption, heading, feed, numberOfItemsToShow, servicePortalUrl } = props;

  const Items = (props: { currentItems: Item[] }) => {
    const { currentItems } = props;

    return (
      <>
        {currentItems &&
          currentItems.map((item) => (
            <ServicePortalRssFeedItem
              key={item.id}
              item={item}
              displayOption={displayOption}
              servicePortalUrl={servicePortalUrl}
            />
          ))}
      </>
    );
  };

  const PaginatedItems = (props: { feed: Result; itemsPerPage: number }) => {
    const [currentItems, setCurrentItems] = useState<Item[]>(null);
    const [pageCount, setPageCount] = useState<number>(0);
    const [itemOffset, setItemOffset] = useState<number>(0);

    const { itemsPerPage } = props;

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(feed.concatenatedOutages.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(feed.concatenatedOutages.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % feed.concatenatedOutages.length;
      setItemOffset(newOffset);
    };

    if (feed.concatenatedOutages.length <= numberOfItemsToShow) return <Items currentItems={currentItems} />;

    return (
      <>
        <Items currentItems={currentItems} />
        {displayOption.tag == 'OneThirdWidth' ? (
          <ReactPaginate
            nextLabel={next}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel={previous}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination pagination-one-third-width"
            activeClassName="active"
          />
        ) : (
          <ReactPaginate
            nextLabel={next}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel={previous}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
      </>
    );
  };

  return (
    <div className="rssfeed block__generic-body">
      <div className="vgr-itemlist vgr-serviceportal-feed">
        <section aria-label="Driftinformation IT" className="vgr-serviceportal-feed__head">
          <div className="block__header vgr-serviceportal-feed__heading">
            {alert}
            <h2 className="block__heading">{heading}</h2>
          </div>
          <div className="vgr-serviceportal-feed__sub-head">
            {descriptiveText !== null && (
              <h3
                className="vgr-serviceportal-feed__sub-heading vgr-listitem"
                dangerouslySetInnerHTML={{ __html: descriptiveText }}
              />
            )}
          </div>
        </section>
        <div className="vgr-serviceportal-feed__body">
          {feed !== null && feed.concatenatedOutages != null && feed.concatenatedOutages.length != 0 ? (
            <PaginatedItems feed={feed} itemsPerPage={numberOfItemsToShow} />
          ) : (
            <article className="vgr-listitem">
              <h3 className="vgr-serviceportal-feed__no-hits">Just nu inga pågående störningar eller avbrott</h3>
            </article>
          )}
        </div>
        <footer className="vgr-serviceportal-feed__footer block__row">
          <a href={servicePortalUrl}>Visa driftinformation i VGR serviceportal</a>
        </footer>
      </div>
    </div>
  );
};

const alert = (
  <svg id="alert" xmlns="http://www.w3.org/2000/svg" height="24" width="24" aria-hidden="true" focusable="false">
    <use href="#streamline-icon-alert-circle" />
  </svg>
);

const next = (
  <svg
    id="alert"
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    width="18"
    aria-hidden="true"
    focusable="false"
    className="svg-next">
    <use href="#streamline-arrow" />
  </svg>
);

const previous = (
  <svg
    id="alert"
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    width="18"
    aria-hidden="true"
    focusable="false"
    className="svg-prev">
    <use href="#streamline-arrow" />
  </svg>
);
