import { useContext } from "react";
import { ActivePageContext, WizardContext } from "./store/wizard.store";

export const WizardTreatmentType: React.VFC<WizardTreatmentTypeProps> = (props) => {
  let { activePage, setActivePage } = useContext(ActivePageContext);
  let { setWizardFormData } = useContext(WizardContext);

  const onClickTreatmentType = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value, name } = e.currentTarget;

    setWizardFormData((prevState) => ({
      ...prevState,
      selectedTreatmentType: { id: parseInt(value), description: name, content: "" },
    }));
    setActivePage(activePage + 1);
  };

  const treatmentTypes = props.ageType.value !== "2" ? props.treatmentTypes : props.childrenTreatmentTypes;

  return (
    <>
      <title>Välj behandlingstyp</title>
      {treatmentTypes.map((treatmentType) => {
        return (
          <div className="g-col-12 expanded-block ftvwebbooking__expanded-block" key={treatmentType.id}>
            <details className="expandable-content expanded-block__details">
              <summary className="expanded-block__heading">
                <h2>{treatmentType.description}</h2>
              </summary>
              <div className="expanded-block__content" dangerouslySetInnerHTML={{ __html: treatmentType.content }} />
              <div className="ftvwebbooking__expanded-block-button">
                <button
                  type="button"
                  className="button-primary link-focus"
                  value={treatmentType.id}
                  name={treatmentType.description}
                  onClick={onClickTreatmentType}
                >
                  Välj
                </button>
              </div>
              <div data-minimize="" className="expanded-block__minimize">
                <button
                  type="button"
                  className="link-focus"
                  aria-controls="expandable-content-1074770"
                >
                  Minimera
                  <div
                    dangerouslySetInnerHTML={{ __html: props.chevronIconSvg }}
                  ></div>
                </button>
              </div>
            </details>
          </div>
        );
      })}
    </>
  );
};