import Select, { ActionMeta } from "react-select";
import { swedishAriaLiveMessages } from "../SearchService/shared/swedishAriaLiveMessages";
import DatePicker, { registerLocale } from "react-datepicker";
import { sv } from 'date-fns/locale/sv';
import { WizardContext } from "./store/wizard.store";
import { useContext, useEffect, useState } from "react";
import { WizardSearchResult } from "./WizardSearchResult";
import * as signalR from "@microsoft/signalr";
registerLocale('sv', sv)

export const WizardSearchStep: React.VFC<WizardSearchStepProps> = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);
    const [isLoadingLastMinute, setIsLoadingLastMinute] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const SSR = typeof window === 'undefined';

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("/ftvBookingHub")
        .build();

    useEffect(() => {
        return () => {
            connection.stop();
        }
    }, [])

    const selectOnChange = (
        option: readonly Option[],
        actionMeta: ActionMeta<Option>
    ) => {
        const options =
            option.length > 0
                ? (option.map((checkbox) => ({
                    value: checkbox.value,
                    label: checkbox.label,
                })) as Option[])
                : [];

        setWizardFormData((prevState) => ({ ...prevState, selectedClinics: options }));
    };

    const onChangeDate = (dates) => {
        setWizardFormData((prevState) => ({ ...prevState, selectedDates: dates }));
    };

    const onClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        setWizardFormData((prevState) => ({ ...prevState, searchResult: [], lastMinuteSearchResult: {} as TimeSlot, showSearchResult: true }));
        getSearchResult();
    }

    const getSearchResult = async () => {
        const request: WizardSearchRequest = {
            ageOfPatient: wizardFormData.ageOfPatient,
            currentPageId: wizardFormData.currentPageId,
            selectedClinics: wizardFormData.selectedClinics,
            selectedDate: wizardFormData.selectedDate,
            SelectedTreatmentType: wizardFormData.selectedTreatmentType,
            skip: 0
        };

        setSkip(0);

        props.activateLastMinute && setIsLoadingLastMinute(true);
        setIsLoading(true);

        await connection.start().then(async () => {
            props.activateLastMinute && connection.send("getLastMinuteTimes", request);
            connection.send("getTimes", request);
        }).catch((err) => console.error(err));

        props.activateLastMinute && connection.on("lastMinuteTimesReceived", (response: TimeSlot) => {
            if (response) {
                setWizardFormData((prevState) => ({ ...prevState, lastMinuteSearchResult: response }));
            } else if (response === null) {
                setWizardFormData((prevState) => ({ ...prevState, lastMinuteSearchResult: null }));
            }
            setIsLoadingLastMinute(false);
        });

        connection.on("timesReceived", (response: TimeSlot[]) => {
            if (response && response.length > 0) {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: [...prevState.searchResult, ...response] }));
            } else {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: null }))
            }
            setIsLoading(false);
        });
    }

    return (
        <div className="grid g-col-12">
            <div className="grid g-col-12 ftvwebbooking__header">
                <div className="g-col-12 g-col-md-6">
                    <label htmlFor="clinics">Sök efter klinik</label>
                    {!SSR ? <Select
                        isMulti={true}
                        id={"clinics"}
                        closeMenuOnSelect={false}
                        name={"clinics"}
                        options={props.selectOptions}
                        value={wizardFormData.selectedClinics}
                        onChange={selectOnChange}
                        placeholder={'Välj klinik...'}
                        defaultValue={props.selectOptions[0]}
                        ariaLiveMessages={swedishAriaLiveMessages}
                        isDisabled={isLoading || isLoadingLastMinute}
                        className="multi-select"
                        classNames={{
                            control: function (state) {
                                if (state.isDisabled) {
                                    return 'multi-select__control multi-select--loading'
                                }
                                return state.isFocused ? 'multi-select__control multi-select__control--focus' : 'multi-select__control';
                            },
                            menuList: (state) => 'multi-select__menu-list',
                            option: (state) => 'multi-select__option',
                            indicatorsContainer: (state) => 'multi-select__indicators-container',
                            multiValue: (state) => 'multi-select__multi-value',
                            multiValueLabel: (state) => 'multi-select__multi-value-label',
                            multiValueRemove: (state) =>
                                state.isFocused
                                    ? 'multi-select__multi-value-remove multi-select__multi-value-remove--focus'
                                    : 'multi-select__multi-value-remove',
                            clearIndicator: (state) => 'multi-select__clear-indicator',
                            placeholder: (state) => 'multi-select__placeholder'
                        }}
                    /> : null}
                </div>
                <div className="g-col-12 g-col-md-4">
                    <label htmlFor="datepicker">Välj datum</label>
                    <DatePicker
                        id="datepicker"
                        wrapperClassName="vgr-datepicker"
                        selected={wizardFormData.selectedDate}
                        onChange={onChangeDate}
                        shouldCloseOnSelect={false}
                        disabled={isLoading || isLoadingLastMinute}
                        disabledKeyboardNavigation
                        locale="sv"
                        minDate={new Date()}
                        dateFormat="yyy/MM/dd"
                    />
                </div>
                <div className="ftvwebbooking__header-button-container g-col-12 g-col-md-2">
                    <button className="button-primary" type="button" onClick={onClickSearch} disabled={isLoading || isLoadingLastMinute}>Sök tider</button>
                </div>
            </div>
            {wizardFormData.showSearchResult && <WizardSearchResult
                infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                infoTextSearchResult={props.infoTextSearchResult}
                lastMinuteResultIcon={props.lastMinuteResultIcon}
                searchResultIcon={props.searchResultIcon}
                isLoading={isLoading}
                isLoadingLastMinute={isLoadingLastMinute}
                setIsLoading={setIsLoading}
                skip={skip}
                setSkip={setSkip}
                activateLastMinute={props.activateLastMinute}
                additionalClinicInformation={props.additionalClinicInformation}
            />}
        </div>
    );
}