import { useContext } from "react";
import { WizardContext } from "./store/wizard.store";

export const WizardConfirmationStep: React.VFC = () => {
    let { wizardFormData } = useContext(WizardContext);

    return (
        <div className="grid g-col-12">
            <input type="hidden" name="currentPageId" value={wizardFormData.currentPageId}></input>
            <input type="hidden" name="serializedTimeSlot" value={JSON.stringify(wizardFormData.selectedTimeSlot)}></input>
            <input type="hidden" name="personalNumber" value={wizardFormData.personalNumber}></input>
            <input type="hidden" name="eMail" value={wizardFormData.eMail}></input>
            <input type="hidden" name="mobilePhonenumber" value={wizardFormData.mobilePhonenumber}></input>
            <input type="hidden" name="altPhonenumber" value={wizardFormData.altPhonenumber}></input>
            <input type="hidden" name="messageForClinic" value={wizardFormData.messageForClinic}></input>
            <input type="hidden" name="confirmationInSms" value={wizardFormData.confirmationInSms.toString()}></input>
            <input type="hidden" name="getInformationFromSkatteverket" value={wizardFormData.getInformationFromSkatteverket.toString()}></input>
            <div className="g-col-12 g-col-md-6">
                <strong>Du vill boka</strong>
                <p>{wizardFormData.ageOfPatient.name}, {wizardFormData.selectedTreatmentType.description}</p>
                <strong>Vid den här tiden</strong>
                <p>
                    {new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getDate() + "/" + (new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getMonth() + 1)},{' '}
                    {new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getHours() + ":" + (new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getMinutes() < 10 ? new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getMinutes() + '0' : new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getMinutes())}
                </p>
                <strong>Hos</strong>
                <p>{wizardFormData.selectedTimeSlot.performerName}</p>
                {wizardFormData.messageForClinic && <strong>Information till kliniken</strong>}
                <p>{wizardFormData.messageForClinic}</p>
                <strong>Bekräftelse via</strong>
                {wizardFormData.confirmationInSms ? <p>E-post och SMS</p> : <p>E-post</p>}
            </div>
            <div className="g-col-12 g-col-md-6">
                <div className="ftvwebbooking__confirmation-wrapper">
                    <div>
                        <strong>Mina personuppgifter</strong>
                    </div>
                    <p>{wizardFormData.personalNumber}</p>
                    <p>{wizardFormData.eMail}</p>
                    <p>{wizardFormData.name + ' ' + wizardFormData.lastName}</p>
                    <p>{wizardFormData.mobilePhonenumber}</p>
                </div>
            </div>
        </div>
    )
}