import { Wizard } from "./Wizard"
import { WizardAgeTypeStep } from "./WizardAgeTypeStep";
import { WizardSearchStep } from "./WizardSearchStep";
import { WizardPersonalDataStep } from "./WizardPersonalDataStep";
import { WizardConfirmationStep } from "./WizardConfirmationStep";

export const WizardContainer: React.VFC<WizardContainerProps> = (props) => {
    const selectOptions: Option[] = [];

    props.additionalClinicInformation.forEach(x => {
        selectOptions.push({
            value: x.multiCheckboxItem.checkboxValue,
            label: x.multiCheckboxItem.checkboxText,
        } as Option);
    });

    return (
        <form action="/FtvBookingWizardPage/BookTime" method="post" className="grid g-col-12">
            <input
                type="hidden"
                name="__RequestVerificationToken"
                id="__RequestVerificationToken"
                value={props.antiforgeryToken}
            ></input>
            <Wizard>
                <WizardAgeTypeStep
                    chevronIconSvg={props.chevronIconSvg}
                    treatmentTypes={props.treatmentTypes}
                    ageTypes={props.ageTypes}
                    currentPageId={props.currentPageId}
                    radioButtonsTitle={props.radioButtonsTitle}
                    childrenTreatmentTypes={props.childrenTreatmentTypes}
                    infoTextAgeType={props.infoTextAgeType}
                />
                <WizardSearchStep
                    selectOptions={selectOptions}
                    antiforgeryToken={props.antiforgeryToken}
                    searchResultIcon={props.searchResultIcon}
                    infoTextSearchResult={props.infoTextSearchResult}
                    infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                    lastMinuteResultIcon={props.lastMinuteResultIcon}
                    activateLastMinute={props.activateLastMinute}
                    additionalClinicInformation={props.additionalClinicInformation}
                />
                <WizardPersonalDataStep antiforgeryToken={props.antiforgeryToken} infoTextMessageToClinic={props.infoTextMessageToClinic} />
                <WizardConfirmationStep />
            </Wizard>
        </form>
    )
}