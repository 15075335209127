import React, { useState } from "react";
import { WizardContext } from "./store/wizard.store";
import { WizardContainer } from "./WizardContainer";

export const FtvWebBookingWizardPage: React.VFC<FtvWebBookingWizardPageProps> = (props) => {
    const [wizardFormData, setWizardFormData] = useState<WizardFormData>({
        selectedClinics: props.additionalClinicInformation.flatMap(x => {
            return x.multiCheckboxItem.selected ?
                {
                    value: x.multiCheckboxItem.checkboxValue,
                    label: x.multiCheckboxItem.checkboxText,
                } as Option : null;
        }),
        selectedDate: new Date(),
        ageOfPatient: { name: "", value: "" },
        currentPageId: props.currentPageId,
        selectedTreatmentType: { id: 0, description: "", content: "" },
        selectedTimeSlot: {} as TimeSlot,
        personalNumber: "",
        altPhonenumber: "",
        eMail: "",
        messageForClinic: "",
        mobilePhonenumber: "",
        confirmationInSms: false,
        validationErrors: {},
        searchResult: [],
        lastMinuteSearchResult: {} as TimeSlot,
        ageSelected: false,
        showSearchResult: false,
        getInformationFromSkatteverket: false,
        name: "",
        lastName: ""
    });

    const formDataValue = { wizardFormData, setWizardFormData };

    return (
        <WizardContext.Provider value={formDataValue}>
            <div className="grid container p-0 ftvwebbooking">
                <div className="grid g-col-12">
                    <div className="ftvwebbooking__ingress g-col-12">
                        <h1>{props.title}</h1>
                        <p>{props.ingress}</p>
                    </div>
                </div>

                <WizardContainer chevronIconSvg={props.chevronIconSvg}
                    additionalClinicInformation={props.additionalClinicInformation}
                    treatmentTypes={props.treatmentTypes}
                    antiforgeryToken={props.antiforgeryToken}
                    ageTypes={props.ageTypes}
                    currentPageId={props.currentPageId}
                    radioButtonsTitle={props.radioButtonsTitle}
                    infoTextSearchResult={props.infoTextSearchResult}
                    searchResultIcon={props.searchResultIcon}
                    infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                    lastMinuteResultIcon={props.lastMinuteResultIcon}
                    childrenTreatmentTypes={props.childrenTreatmentTypes}
                    activateLastMinute={props.activateLastMinute}
                    infoTextMessageToClinic={props.infoTextMessageToClinic}
                    infoTextAgeType={props.infoTextAgeType}
                />
            </div>
        </WizardContext.Provider>
    );
}