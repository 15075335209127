import React, { useState } from "react";
import { ServicePortalRssFeedItemProps } from "./types";

export const ServicePortalRssFeedItem = (
  props: ServicePortalRssFeedItemProps
) => {
  
  const [expanded, setExpanded] = useState<boolean>(false);

  const {
    displayOption,
    item
  } = props;


  const handleClick = () => {
    setExpanded(expanded => !expanded);
  }

  return (
    <article className="vgr-listitem">
      {displayOption.tag === "OneThirdWidth" ?
        <header style={{display: "flex"}}>
          <h3 className="vgr-listitem__heading">
            {item.message}
          </h3>
          <div className="vgr-listitem__badge-wrapper">
            {item.type === "outage" && 
              <div className="vgr-badge vgr-badge--error">
                Avbrott
              </div>
            }
            {item.type === "degradation" &&
              <div className="vgr-badge vgr-badge--warning">
                Störning
              </div>
            }
            {item.type === "planned" &&
              <div className="vgr-badge vgr-badge--info">
                Planerat arbete
              </div>
            }
          </div>
        </header> :
        <header>
        <h3 className="vgr-listitem__heading">
          {item.message}
        </h3>
        <div className="vgr-listitem__badge-wrapper">
          {item.type === "outage" && 
            <div className="vgr-badge vgr-badge--error">
              Avbrott
            </div>
          }
          {item.type === "degradation" &&
            <div className="vgr-badge vgr-badge--warning">
              Störning
            </div>
          }
          {item.type === "planned" &&
            <div className="vgr-badge vgr-badge--info">
              Planerat arbete
            </div>
          }
        </div>
      </header> 
      }
      {item.updated_onString && item.type !== "planned" ? 
        item.updated_onString && (
          <h4 className="vgr-listitem__sub-heading">
            {item.updated_onString}
          </h4>
        ) :
        item.beginString && (
          <h4 className="vgr-listitem__sub-heading start-string">
            <p>Startar:</p> {item.beginString}
          </h4>
          )
        }
      {item.endString &&
        <h4 className="vgr-listitem__sub-heading end-string">
          <p>Slutar:</p> {item.endString}
        </h4>
      }
      <div className="vgr-listitem__body">
        <details className="vgr-serviceportal-feed__details">
          <summary onClick={handleClick}>
            {expanded ? 
            <div className="vgr-serviceportal-feed__chevron-wrapper open" aria-label={"Visa mindre innehåll om " + item.message}>Visa mindre innehåll {chevron}</div> 
            :             
            <div className="vgr-serviceportal-feed__chevron-wrapper" aria-label={"Visa mer innehåll om " + item.message}>Visa mer innehåll {chevron}</div>
            }
          </summary>
          {item.details != null &&
            <div className="vgr-serviceportal-feed__details-text" dangerouslySetInnerHTML={{ __html: item.details }} />
          }
          {item.attachments.length != 0 && 
            <div className="vgr-serviceportal-feed__details-attachments">
            <strong>Bifogade filer</strong>
              {item.attachments.map(item => (
                <div key={item.name} className="vgr-serviceportal-feed__details-attachment">
                  {attachmentIcon}
                  <a href={item.link}>{item.name}</a>
                </div>
              ))}
            </div>
          }
          <div className="vgr-serviceportal-feed__details-text">
            <ul>
              <li>
                <a href={item.link_to_plexus}>Visa driftsinformation för tjänsten i VGR IT serviceportal</a>
              </li>
              <li>
                <a href={item.link_to_plexus}>Prenumerera på driftmeddelanden för tjänsten via e-post</a>
              </li>
            </ul>
          </div>
        </details>
      </div>
    </article>
  );
};

const chevron = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#005C95"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    aria-hidden="true"
    focusable="false"
  >
      <use href="#streamline-arrow" />
  </svg>
);

const attachmentIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#005C95"
    height="14"
    aria-hidden="true"
    focusable="false"
  >
    <use href="#fontawesome-icon-attachment" />
  </svg>
)
