import { useContext } from "react";
import { WizardContext } from "./store/wizard.store";
import { WizardTreatmentType } from "./WizardTreatmentType";

export const WizardAgeTypeStep: React.VFC<WizardAgeTypeStepProps> = (
  props
) => {
  let { wizardFormData, setWizardFormData } = useContext(WizardContext);

  const onChangeAge = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { id, name } = e.target;

    setWizardFormData((prevState) => ({
      ...prevState,
      ageOfPatient: { name, value: id },
      ageSelected: true
    }));
  };

  return (
    <div className="grid g-col-12">
      <input type="hidden" name="currentPageId" value={props.currentPageId} />
      <fieldset className="g-col-12">
        <legend>{props.radioButtonsTitle}</legend>
        {props.ageTypes.map((ageType) => {
          return (
            <div key={ageType.id} className="ftvwebbooking__radios">
              <label>
                {ageType.name}
                <input
                  type="radio"
                  id={ageType.id.toString()}
                  value={wizardFormData.ageOfPatient.value}
                  name={ageType.name}
                  checked={ageType.id === parseInt(wizardFormData.ageOfPatient.value)}
                  onChange={onChangeAge}
                />
              </label>
            </div>
          );
        })}
      </fieldset>
      {wizardFormData.ageSelected && props.infoTextAgeType ? <WizardTreatmentType ageType={wizardFormData.ageOfPatient} treatmentTypes={props.treatmentTypes} chevronIconSvg={props.chevronIconSvg} childrenTreatmentTypes={props.childrenTreatmentTypes} />
        : <div className="ftvwebbooking info-text g-col-12" dangerouslySetInnerHTML={{ __html: props.infoTextAgeType }}></div>}
    </div>
  );
};
