import { Children, useContext, useEffect, useState } from "react";
import { ActivePageContext, WizardContext } from "./store/wizard.store";
import { validateWizardFormData } from "./utils/ValidationUtil";

export const Wizard: React.FC = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);
    const [activePage, setActivePage] = useState(0);
    const pages = Children.toArray(props.children);
    const currentPage = pages[activePage];
    const activePageContextValue = { activePage, setActivePage };

    useEffect(() => {
        typeof window !== 'undefined' && window.scrollTo(0, 0);
    }, [activePage])

    const goNextPage = () => {
        const errors = validateWizardFormData(wizardFormData);

        setWizardFormData((prevState) => ({ ...prevState, validationErrors: errors }));

        if (Object.values(errors).length == 0) setActivePage(activePage + 1);
    };

    const goPrevPage = () => {
        setActivePage(activePage - 1);
    };

    const goToPage = (e: React.MouseEvent<HTMLButtonElement>) => {
        setActivePage(parseInt(e.currentTarget.id));
    }

    const ButtonPrev = () =>
        activePage > 0 ? (
            <button
                type="button"
                onClick={goPrevPage}
                className="g-col-6 g-col-md-2"
            >
                Gå tillbaka
            </button>
        ) : null;

    const ButtonNext = () => {
        if (activePage > 1 && activePage < pages.length - 1) {
            return (
                <button
                    type="button"
                    onClick={goNextPage}
                    className="g-col-6 g-col-md-2 g-start-11 button-primary"
                    disabled={Object.values(wizardFormData.validationErrors).length > 0}
                >
                    Gå vidare
                </button>
            )
        } else if (activePage == pages.length - 1) {
            return (
                <button
                    type="submit"
                    className="g-col-6 g-col-md-2 g-start-11 button-primary"
                    disabled={Object.values(wizardFormData.validationErrors).length > 0}
                >
                    Boka tiden
                </button>
            )
        } else {
            return null;
        }
    }

    const StepText = () => {
        if (activePage == 0) return "Ålder och behandling";
        else if (activePage == 1) return "Klinik och tid";
        else if (activePage == 2) return "Mina uppgifter";
        else if (activePage == 3) return "Bekräfta och skicka";
    }

    const NextStepText = () => {
        if (activePage == 0) return "Sök tider";
        else if (activePage == 1) return "Personuppgifter";
        else if (activePage == 2) return "Bekräfta & skicka";
    }

    const activeClass = (step: number) => {
        if (activePage == step) return "wizardstep g-col-3 active";
        else if (activePage >= step) return "wizardstep g-col-3 prevactive";
        else return "wizardstep g-col-3";
    }

    return (
        <div className="grid g-col-12">
            <div className="grid g-col-12" style={{ rowGap: "1rem" }}>
                <ol className="wizardsteps grid g-col-12">
                    <li className="wizardstep g-col-2">
                        <button type="button" id="0" onClick={goToPage} className={activePage == 0 ? "button-primary" : ""}>1</button>
                        <i className="wizardstep__arrow"></i>
                    </li>
                    <li className="wizardstep g-col-2">
                        <button type="button" id="1" onClick={goToPage} disabled={activePage < 1} className={activePage == 1 ? "button-primary" : ""}>2</button>
                        <i className="wizardstep__arrow"></i>
                    </li>
                    <li className="wizardstep g-col-2">
                        <button type="button" id="2" onClick={goToPage} disabled={activePage < 2} className={activePage == 2 ? "button-primary" : ""}>3</button>
                        <i className="wizardstep__arrow"></i>
                    </li>
                    <li className="wizardstep g-col-2">
                        <button type="button" id="3" onClick={goToPage} disabled={activePage < 3} className={activePage == 3 ? "button-primary" : ""}>4</button>
                    </li>
                </ol>
                <div className="wizardsteps__text grid g-col-12">
                    <strong className="g-col-2">Ålder och behandling</strong>
                    <strong className="g-col-2">Klinik och tid</strong>
                    <strong className="g-col-2">Mina uppgifter</strong>
                    <strong className="g-col-2">Bekräfta och skicka</strong>
                </div>
            </div>
            <div className="wizardsteps--mobile g-col-12">
                <h2>
                    Steg {activePage + 1} av 4: {StepText()}
                </h2>
                {activePage !== 3 && <p>Nästa: {NextStepText()}</p>}
            </div>
            <div className="wizardprogress g-col-12">
                <ul className="g-col-12">
                    <li className={activeClass(0)}>
                        <span></span>
                    </li>
                    <li className={activeClass(1)}>
                        <span></span>
                    </li>
                    <li className={activeClass(2)}>
                        <span></span>
                    </li>
                    <li className={activeClass(3)}>
                        <span></span>
                    </li>
                </ul>
            </div>
            <div className="g-col-12">
                <ActivePageContext.Provider value={activePageContextValue}>
                    {currentPage}
                </ActivePageContext.Provider>
            </div>
            <div className="grid g-col-12">
                <ButtonPrev />
                <ButtonNext />
            </div>
        </div>
    )
}