import React, { useContext, useState } from "react";
import { WizardContext } from "./store/wizard.store";
import { validateWizardFormData } from "./utils/ValidationUtil";

export const WizardPersonalDataStep: React.VFC<WizardPersonalDataStepProps> = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);
    const [folkbokforingsUppgifter, setFolkbokforingsUppgifter] = useState<SkatteverketResponse>();
    const [showFolkbokforingsUppgifter, setShowFolkbokforingsUppgifter] = useState(false);
    const [showError, setShowError] = useState(false);

    const onChangeWizardFormData = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setWizardFormData((prevState) => ({ ...prevState, [e.target.id]: e.target.value }))
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => {
        const errors = validateWizardFormData(wizardFormData, e.target.id);

        setWizardFormData((prevState) => ({ ...prevState, validationErrors: errors }))
    }

    const getPersonalInfo = async () => {
        setWizardFormData((prevState) => ({ ...prevState, getInformationFromSkatteverket: true }));

        const request = {
            method: "POST",
            headers: {
                "Requestverificationtoken": props.antiforgeryToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(wizardFormData.personalNumber),
        };
        const response = await fetch("/FtvBookingWizardPage/GetPersonalInformation", request)
            .then(data => data.text());

        const result: SkatteverketResponse = JSON.parse(response);

        if (!result.error) {
            setFolkbokforingsUppgifter(JSON.parse(response));

            setWizardFormData((prevState) => ({ ...prevState, name: result.fornamn, lastName: result.efternamn }))

            setShowFolkbokforingsUppgifter(true);
        } else {
            setShowError(true);
        }
    }
    return (
        <div className="ftvwebbooking__personal-data-step grid g-col-12">
            <div className="grid g-col-12 ftvwebbooking__header">
                <div className="g-col-12 g-col-md-6">
                    <label htmlFor="personalNumber">Personnummer <span className="invalid">*</span></label>
                    <input id="personalNumber" placeholder="ÅÅÅÅMMDDXXXX" value={wizardFormData.personalNumber} onChange={onChangeWizardFormData} onBlur={onBlur}></input>
                    {wizardFormData.validationErrors && <span className="invalid">{wizardFormData.validationErrors["personalNumber"]}</span>}
                </div>
                <div className="ftvwebbooking__header-button-container g-col-12 g-col-md-4">
                    <button className="button-primary" type="button" onClick={getPersonalInfo}>Hämta personuppgifter</button>
                </div>
                {showError &&
                    <div className="g-col-12 invalid">
                        <p className="mb-0">Något gick fel vid hämtning av personuppgifter, vänligen kontakta kliniken på telefon för att boka tid eller försök igen senare.</p>
                    </div>}
            </div>
            <span className="g-col-12">Fyll i ditt personnummer så hämtas dina personuppgifter automatiskt.</span>
            {showFolkbokforingsUppgifter &&
                <div className="grid g-col-12">
                    <div className="g-col-6">
                        <label htmlFor="fornamn">Förnamn</label>
                        <input readOnly disabled id="fornamn" value={folkbokforingsUppgifter.fornamn}></input>
                    </div>
                    <div className="g-col-6">
                        <label htmlFor="efternamn">Efternamn</label>
                        <input readOnly disabled id="efternamn" value={folkbokforingsUppgifter.efternamn}></input>
                    </div>
                </div>}
            <div className="grid g-col-12 ftvwebbooking__personal-data">
                <div className="g-col-12">
                    <label htmlFor="eMail">E-post <span className="invalid">*</span></label>
                    <input type="email" id="eMail" autoComplete="email" value={wizardFormData.eMail} onChange={onChangeWizardFormData} onBlur={onBlur}></input>
                    {wizardFormData.validationErrors && <span className="invalid">{wizardFormData.validationErrors["eMail"]}</span>}
                </div>
                <div className="grid g-col-12">
                    <div className="g-col-12 g-col-md-6">
                        <label htmlFor="mobilePhonenumber">Mobilnummer <span className="invalid">*</span></label>
                        <input type="tel" id="mobilePhonenumber" autoComplete="tel" value={wizardFormData.mobilePhonenumber} onChange={onChangeWizardFormData} onBlur={onBlur}></input>
                        {wizardFormData.validationErrors && <span className="invalid">{wizardFormData.validationErrors["mobilePhonenumber"]}</span>}
                    </div>
                    <div className="g-col-12 g-col-md-6">
                        <label htmlFor="altPhonenumber">Alternativt telefonnummer <small>(frivillig)</small></label>
                        <input type="tel" id="altPhonenumber" autoComplete="tel" value={wizardFormData.altPhonenumber} onChange={onChangeWizardFormData}></input>
                    </div>
                </div>
                <div className="g-col-12">
                    <label htmlFor="messageForClinic">Information till kliniken <small>(frivillig)</small></label>
                    <div dangerouslySetInnerHTML={{ __html: props.infoTextMessageToClinic }}></div>
                    <textarea id="messageForClinic" value={wizardFormData.messageForClinic} onChange={onChangeWizardFormData}></textarea>
                </div>
                <div className="g-col-12">
                    <strong>Bekräftelse via e-post eller SMS</strong>
                    <p>Du kommer få en bokningsbekräftelse via e-post. Ange om du även vill ha den som SMS.</p>
                    <div className="ftvwebbooking__checkboxes">
                        <input type="checkbox" id="confirmationInSms" value={wizardFormData.confirmationInSms.toString()} checked={wizardFormData.confirmationInSms} onChange={onChangeWizardFormData}></input>
                        <label htmlFor="confirmationInSms">Bekräftelse via SMS</label>
                    </div>
                </div>
            </div>
        </div >
    );
}