export const validateWizardFormData = (formData: WizardFormData, id?: string): Record<string, string> => {
    const errors: Record<string, string> = { ...formData.validationErrors };

    const personalNumberRegEx = /^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/;

    //First validate that the field is not empty
    if (!formData[id] && id) errors[id] = "Fältet är obligatorisk";
    //Then validate the individual conditions
    else if (id === "personalNumber" && !personalNumberRegEx.test(formData[id])) errors[id] = "Fältet måste innehålla ett giltigt personnummer";
    else if (errors[id]) delete errors[id];
    else if (!id) {
        if (!formData["personalNumber"]) errors["personalNumber"] = "Fältet är obligatorisk";
        if (!formData["eMail"]) errors["eMail"] = "Fältet är obligatorisk";
        if (!formData["mobilePhonenumber"]) errors["mobilePhonenumber"] = "Fältet är obligatorisk";
        if (!personalNumberRegEx.test(formData["personalNumber"])) errors["personalNumber"] = "Fältet måste innehålla ett giltigt personnummer";
    }

    return errors;
}